<template>
  <div class="text-left SignUpSignIn">
    <div v-if="isLoading">
      <LoaderComp />
    </div>
    <div v-else class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-7 MainSection">
        <div>
            <div class="px-5">
                <router-link to="/">
                    <img
              src="/assets/img/new-website/LogoQuizell.svg"
              style="height: 40px; cursor: pointer"
              alt="Quizell"
              class="img-fluid"
            />
                </router-link>
           
          </div>
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center"
          style="min-height: 90%"
        >
          <div class="TitleDiv">
            <h1 class="title">Help us to know you better!</h1>
            <h5 class="title" style="font-size: 18px">
                Add Your Details to Your Existing Account!
            </h5>
          </div>

          <div class="mt-4 FormDiv">
            <div class="new-form-element">
              <p class="m-0 pl-1">
                Email address <span style="color: #969696">*</span>
              </p>
              <div class="NewFormInput d-flex align-items-center w-100">
                <input
                  type="email"
                  class="flex-grow-1 m-0"
                  placeholder="example@mail.com"
                  v-model="userEmail"
                />
              </div>
            </div>

            <div class="new-form-element mt-3">
              <p class="m-0 pl-1">
                Business name <span style="color: #969696">*</span>
              </p>
              <div class="NewFormInput d-flex align-items-center w-100">
                <input
                  type="text"
                  class="flex-grow-1 m-0"
                  placeholder="Enter your Business name"
                  v-model="userName"
                />
              </div>
            </div>

            <div class="mt-4 w-100">
              <button
                :disabled="!isAccountUpdateValid"
                @click="updateWixRecords"
                class="btn w-100 NewFormInput-Btn btn-padding"
              >
                <span v-if="!isUpdateSpinner">Update</span>
                <div
                  v-else
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-none d-lg-block col-lg-5 objectSection lazyload"
        :data-bg="'https://www.quizell.com/_nuxt/img/SignUpNewBg.5f2dc1c.svg'"
      >
        <LazyImage
          :src="'/assets/img/signIn_img.svg'"
          :title="`Quizell login`"
          :alt="`Quizell login`"
          style="height: 100%; width: 100%"
          :loading="'lazy'"
        />
      </div>
    </div>
  </div>
</template>

<script>
 import axios from "axios";
import LoaderComp from "../customize/component/LoaderComp.vue";
export default {
  name: "WixAccountUpdate",

  components: {
    LoaderComp,
  },

  data() {
    return {
      hideApps: false,
      isLoading: false,
      isUpdateSpinner: false,
      userEmail: "",
      userName: "",
    };
  },

  methods: {
    checkEmailValidity(email) {
  const reg = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g);
  return reg.test(email);
},

    async updateWixRecords() {
      if (!this.checkEmailValidity(this.userEmail)) {
        this.$toasted.show("Invalid email. Please provide correct email", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });

        return;
      }

      let payload = {
        name: this.userName,
        email: this.userEmail,
      };

      try {
        this.isUpdateSpinner = true;
            const response = await axios.post(`/updateWixUserEmail`,payload)

            if(response.status == 200){
                    this.$router.push("/manage/quizzes").then(() => { this.$router.go(0) })
            } else{
                this.$toasted.show("Opps something went wrong! Please try again.", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
            }
      } catch (error) {
        if (error.response) {

            let {message ,errors} = error.response.data
            let emailErrors = errors?.email
            if(emailErrors && emailErrors.length > 0){
                for(let i=0 ;i<emailErrors.length;i++){
                    this.$toasted.show(emailErrors[i], {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
                }
            }
            else{
                this.$toasted.show(message, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
            }   
         
        }
      } finally {
        this.isUpdateSpinner = false;
      }
    },
  },
  computed: {
    isAccountUpdateValid() {
      return this.userName !== "" && this.userEmail !== "";
    },
  },
};
</script>
<style>
.NewFormInput {
  border: 1px solid #dedede;
  border-radius: 12px;
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  padding: 14px 24px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.NewFormInput input {
  background: transparent;
  border: none;
  outline: none;
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.NewFormInput input:focus {
  border: none;
  outline: none;
}
.NewFormInput input::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  text-align: start;

  color: #d1d1d1;
}

.NewFormInput-Btn {
  background: #4d1b7e;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.NewFormInput-Btn:hover {
  background: #ffc000;
  color: #ffffff;
}
.NewFormInput-Btn:disabled:hover {
  background: #4d1b7e !important;
}
.new-form-element {
  color: #161e34;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
<style scoped>
/* @font-face {
  font-family: 'Gilroy'; 
  src: 
       url('~@/assets/fonts/Gilroy-Light.otf')  format('opentype');
 
  font-weight: normal;
  font-style: normal;
} */
.SignUpSignIn {
  overflow-x: hidden;
}
.h2 {
  font-family: "Poppins";

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #161e34;
}
.SignUpSignIn .MainSection {
  padding: 1% 5%;
  /* height:100%; */
  min-height: 100vh;
  overflow-y: scroll;
  background: #ffffff;
}

.MainSection .title {
  font-family: "Poppins";
  /* font-family: 'Gilroy'; */
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38.82px;
  text-align: center;
  color: #161e34;
}
.MainSection .titleDesc h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #161e34;
  padding-bottom: 20px;
}
.objectSection {
  background-color: #f6edff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 560px) {
  .MainSection .title {
    font-weight: 500;
    font-size: 26px;
    line-height: 60px;
  }

  .MainSection .TitleDiv {
    padding: 28px;
  }
}

/* Btn Group Styles */

.BtnGroup {
  border-radius: 12px;
  background: #f0f0f2;
  padding: 5px;
  max-width: 500px;
}
.BtnGroup .linkBtn {
  display: flex;
  padding: 12px 56px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #7a7a7a;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.BtnGroup .linkBtn:hover {
  text-decoration: none;
  color: inherit;
}
.BtnGroup .linkBtn.ActiveLink {
  background: #fff;
  color: #000;
  font-size: 14px;
  border-radius: 8px;
  font-weight: 600;
}

.continueDiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #969696;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.continueDiv .lineElement {
  height: 1px;
  flex-grow: 1;
  background: #969696;
}

.continueDiv .textElement {
  flex: 1;
}

.SignUpPlatformImg {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.FormDiv {
  width: 50%;
}

@media (max-width: 768px) {
  .FormDiv {
    width: 100%;
  }
}
@media (max-width: 1500px) {
  .FormDiv {
    width: 70%;
  }
}
</style>
